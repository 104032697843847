import React from 'react';
import { Link } from 'gatsby';
import { isAuthenticated, logout, login } from '../utils/auth.js'

const LoginOrLogout = () => {
  if (!isAuthenticated()) {
    return (<li>
      <a
        href="#login"
        onClick={e => {
          login()
          e.preventDefault()
        }}
      >
        Log In
      </a>
    </li>
    )
  }
  else {
    return (<li>
      <a
        href="#logout"
        onClick={e => {
          logout()
          e.preventDefault()
        }}
      >
        Log Out
      </a>
    </li>)
  }
}

const ListSMSFunctions = ({ onMenuToggle = () => { } }) => {
  if (isAuthenticated()) {
    return (<li>
      <Link
        onClick={e => {
          onMenuToggle();
        }}
        to="/sms"
      >
        SMS
      </Link>
    </li>
    )
  }
  else {
    return null;
  }
}

const ListWebsiteFunctions = ({ onMenuToggle = () => { } }) => {
  if (isAuthenticated()) {
    return (<li>
      <Link
        onClick={e => {
          onMenuToggle();
        }}
        to="/website"
      >
        Website Links
      </Link>
    </li>
    )
  }
  else {
    return null;
  }
}

const ListTodoFunctions = ({ onMenuToggle = () => { } }) => {
  if (isAuthenticated()) {
    return (<li>
      <Link
        onClick={e => {
          onMenuToggle();
        }}
        to="/todo"
      >
        Todo List
      </Link>
    </li>
    )
  }
  else {
    return null;
  }
}

const ListNotesFunctions = ({ onMenuToggle = () => { } }) => {
  if (isAuthenticated()) {
    return (<li>
      <Link
        onClick={e => {
          onMenuToggle();
        }}
        to="/notes"
      >
        Notes
      </Link>
    </li>
    )
  }
  else {
    return null;
  }
}

const ListOrdersFunctions = ({ onMenuToggle = () => { } }) => {
  if (isAuthenticated()) {
    return (<li>
      <Link
        onClick={e => {
          onMenuToggle();
        }}
        to="/orders"
      >
        Orders
      </Link>
    </li>
    )
  }
  else {
    return null;
  }
}

const Nav = ({ onMenuToggle = () => { } }) => {
  return (
    <nav id="menu">
      <div className="inner">
        <h2>Menu</h2>
        <ul className="links">
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="/"
            >
              Home
            </Link>
          </li>
          <ListSMSFunctions />
          <ListWebsiteFunctions />
          <ListTodoFunctions />
          <ListNotesFunctions />
          <ListOrdersFunctions />
          <LoginOrLogout />
        </ul>
        <a
          className="close"
          onClick={e => {
            e.preventDefault();
            onMenuToggle();
          }}
          href="#menu"
        >
          {''}
        </a>
      </div>
    </nav>
  );
}

export default Nav;