import React, { useState } from 'react';
import Nav from './Nav';
import { Link } from 'gatsby';

const isBrowser = typeof window !== "undefined"

const SideBar = ({ fullMenu }) => {
  const [headerOpen, toggleHeader] = useState(false);

  let userName = "";

  if(isBrowser)
  {
    userName = localStorage.getItem("user.name")
  }
  
  return (
    <>
      <header id="header" className={`${fullMenu ? '' : 'alt'}`}>
        <h1>
          <Link to="/">BWC Admin</Link> {userName}
        </h1>
        
        <nav>
          <a
            href="#menu"
            onClick={e => {
              e.preventDefault();
              toggleHeader(!headerOpen);
            }}
            className="menuToggle"
          >
            <span>Menu</span>
          </a>
        </nav>
      </header>
      <div className={`${headerOpen ? 'is-menu-visible' : ' '}`}>
        <Nav onMenuToggle={() => toggleHeader(!headerOpen)} />
      </div>
    </>
  );
}

export default SideBar;